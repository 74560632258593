import React from 'react';
import { SEO } from 'components/SEO';
import { ActionLeadModal } from 'containers/Modals/ActionLeadModal';
import { MainBlock } from './MainBlock';
import { PromotionListBlock } from './PromotionListBlock';
import './styles.scss';

const Promotions = () => {
  const [actionLeadModalOpened, setActionLeadModalOpened] =
    React.useState(false);
  const onActionLeadModalClose = React.useCallback(() => {
    setActionLeadModalOpened(false);
  }, []);
  const onActionLeadModalOpen = React.useCallback(() => {
    setActionLeadModalOpened(true);
  }, []);

  return (
    <main className="page promotions-page">
      <SEO
        title="Акции"
        description="Скидки и акции для детей на обучения: программированию, математике и шахматам в нашей онлайн-школе UP! School"
      />
      <MainBlock />
      <PromotionListBlock onActionLeadModalOpen={onActionLeadModalOpen} />
      <ActionLeadModal
        opened={actionLeadModalOpened}
        onClose={onActionLeadModalClose}
      />
    </main>
  );
};

export default Promotions;
