import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { LocationContext } from 'containers/Location/LocationContext';
import { Form } from './Form';
import './styles.scss';

export interface IActionLeadModalData {
  opened: boolean;
}

interface IProps extends IActionLeadModalData {
  onClose: () => void;
}

export const ActionLeadModal = ({ opened, onClose }: IProps) => {
  const location = React.useContext(LocationContext);
  return (
    <Modal
      className="action-lead-modal"
      show={opened}
      onHide={onClose}
      backdropClassName="backdrop-light"
      centered
    >
      <Modal.Body>
        <button onClick={onClose} className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 className="title">
          Заполните данные в форме заявки и получите <b>2 бесплатных</b> занятия
          вам и другу!
        </h4>
        <Form onSuccess={onClose} location={location} />
      </Modal.Body>
    </Modal>
  );
};
