import React from 'react';
import { PromotionCard } from './PromotionCard';

interface IProps {
  onActionLeadModalOpen: () => void;
}

export const PromotionListBlock = ({ onActionLeadModalOpen }: IProps) => (
  <section className="promotion-list-block container">
    <h2 className="title">Скидки и акции</h2>
    <div className="promotion-list">
      <div className="promotion-item">
        <PromotionCard onActionBtnClick={onActionLeadModalOpen} />
      </div>
    </div>
  </section>
);
