import Button from 'react-bootstrap/Button';
import chessBoxImg from './images/chess_box.png';
import calendarImg from './images/calendar.svg';
import React from 'react';

interface IProps {
  onActionBtnClick: () => void;
}

export const PromotionCard = ({ onActionBtnClick }: IProps) => (
  <div className="promotion-card">
    <img src={chessBoxImg} className="promotion-img" alt="Пакет занятий" />
    <div className="promotion-details">
      <div className="course-name">Все курсы</div>
      <h4 className="title">Акция "Приведи друга"</h4>
      <div className="subtitle">
        Приведите друга и получите по <b>2 бесплатных</b> занятия вам и другу
      </div>
      <Button
        onClick={onActionBtnClick}
        variant="secondary"
        size="lg"
        className="action-btn btn-shine"
      >
        Пригласить
      </Button>
    </div>
    <div className="promotion-date">
      <img src={calendarImg} alt="Календарь" />
      <span>До 2 августа 2021</span>
    </div>
  </div>
);
