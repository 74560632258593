import React from 'react';
import Button from 'react-bootstrap/Button';
import { BackgroundImg } from './BackgroundImg';

export const MainBlock = () => {
  const onClick = React.useCallback(() => {
    (
      document.querySelector('.promotion-list-block') as HTMLDivElement
    ).scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <section className="main-block container">
      <div className="background-top" />
      <div className="main-proposition">
        <h1 className="title">Мощные скидки этого Месяца</h1>
        <div className="subtitle">
          На этой странице собраны самые выгодные спецпредложения. Пользуйтесь с
          удовольствием!
        </div>
        <Button onClick={onClick} variant="secondary" size="lg">
          Посмотреть
        </Button>
      </div>
      <BackgroundImg />
    </section>
  );
};
