import { API_PATHS, createAxiosInstance } from '../constants';
import { IError, ISuccess } from '../types';
import { IFriendLeadData } from './types';

export const createFriendLeadRequest = async (
  friendLead: IFriendLeadData,
): Promise<IError | ISuccess> => {
  const axiosInstance = createAxiosInstance();
  await axiosInstance.post(API_PATHS.landing.leadsFriend, friendLead);
  return { success: true };
};
